import React, { } from 'react'
import Mannschaft from '../components/Mannschaft';

const seasons = [
    2023,
    2022,
    2021
]

function Mannschaften() {

    return (
        <section className="bg-white w-full dark:bg-gray-900">
            <div className="py-8 mx-auto lg:px-0">
                <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8 absolute top-80 sm:top-72 md:top-72 lg:top-72 xl:top-72 left-0 right-0">
                    <h2 className="mb-4 text-2xl sm:text-3xl lg:text-4xl tracking-tight font-extrabold text-white dark:text-white">Mannschaften</h2>
                    <p className="font-light text-slate-100 text-sm sm:text-lg lg:text-xl text-white dark:text-gray-400">Die aktuellen Tabellen findest du <a href='https://www.mytischtennis.de/clicktt/STTB/22-23/verein/328/TTC-Rehlingen/mannschaften/' target="_blank" rel='noreferrer' className='text-blue-300 cursor-pointer hover:underline'>hier</a></p>
                </div>
                <div className='bg-white w-full 2xl:px-14 3xl:px-56 pt-0'>
                    <div className="flex flex-row justify-center items-center">
                        
                        <section className="bg-white dark:bg-gray-900">
                            <div className="grid max-w-screen-2xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-8 grid-cols-1 lg:grid-cols-12">
                                <div className="mr-auto place-self-center lg:col-span-7 flex flex-col">
                                    <h1 className="max-w-2xl mb-4 text-xl lg:text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">Rückblick</h1>
                                    <p className="max-w-2xl mb-2 font-light text-gray-500 text-sm md:text-lg lg:text-xl dark:text-gray-400">Sie können sich hier die Ergebnisse unserer Mannschaften der vergangenen Saisons anschauen. </p>
                                    
                                </div>
                               {/*  <div className="hidden lg:mt-0 lg:col-span-5 lg:flex w-60 h-60">
                                    <img src={require("../assets/logo.png")} alt="logo"/>
                                </div> */}
                            </div>
                        </section>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
                    {seasons.map(filter => (
                        <Mannschaft selectedId={filter} />
                    ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Mannschaften