import React, { useEffect, useState } from "react";
import { supabase } from "./../supabase/init.ts";

export default function DorfSpieltTT() {
  const [formData, setFormData] = useState({
    mannschaft1: "",
    mannschaft2: "",
    mannschaft3: "",
    name: "",
    address: "",
    number: "",
    email: "",
  });

  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
        const {data: events} = await supabase.from("events").select("*");
        console.log(events);
        setEvents(events);
    };
    fetchData();
    }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    
    const { data, error } = await supabase.from("dstt_registrations").insert([
        {
            teamName: formData.mannschaft1,
            event_id: 'df95ded7-8196-4963-9c55-2b22c53a0d19',
            contactName: formData.name,
            contactAddress: formData.address,
            contactNumber: formData.number,
            contactEmail: formData.email,
        }
    ]);

    if (error) {
        console.log(error);
    } else {
        console.log(data);
    }
  };

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 sm:mx-auto">
        <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8 absolute top-80 sm:top-72 md:top-72 lg:top-72 xl:top-72 left-0 right-0">
          <h2 className="mb-4 text-2xl sm:text-3xl lg:text-4xl tracking-tight font-extrabold text-white dark:text-white">
            Dorf spielt Tischtennis 2024
          </h2>
          <p className="font-light text-slate-100 text-sm sm:text-lg lg:text-xl text-white dark:text-gray-400">
            Hier können Sie sich für das Turnier anmelden.
          </p>
          {events.map((event) => (
                <p>{event.name}</p>
        ))}
        </div>
        <div className="w-11/12 sm:w-3/4 lg:w-1/2 mx-auto pt-4 pb-8 flex ">
        Anmeldung zum Turnier „Rehlingen spielt Tischtennis“
        am 28.12.2024 ab 10 Uhr (vorher Einspielen und
        Auslosung) in der Schulturnhalle Rehlingen
        </div>
        <div className="bg-white w-11/12 sm:w-3/4 lg:w-1/2 mx-auto pt-4 flex justify-center">
          <form onSubmit={handleSubmit} className="space-y-4 w-full">
            <div className="flex flex-row gap-5">
                <label
                    for="message"
                    className="self-center text-sm font-medium text-gray-900 dark:text-gray-400 w-40"
                >
                    Mannschaftsname 1
                </label>
                <input
                    type="text"
                    id="mannschaft1"
                    className="shadow-sm flex-1 bg-gray-200 border-none text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="Mannschaftsname 1 hier eintragen"
                    value={formData.mannschaft1}
                    onChange={handleChange}
                    required
                />
            </div>

            <div className="flex flex-row gap-5">
                <label
                    for="message"
                    className="self-center text-sm font-medium text-gray-900 dark:text-gray-400 w-40"
                >
                    Mannschaftsname 2
                </label>
                <input
                    type="text"
                    id="mannschaft2"
                    className="shadow-sm flex-1 bg-gray-200 border-none text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="Mannschaftsname 2 hier eintragen (falls erwünscht)"
                    value={formData.mannschaft2}
                    onChange={handleChange}
                />
            </div>

            <div className="flex flex-row gap-5">
                <label
                    for="message"
                    className="self-center text-sm font-medium text-gray-900 dark:text-gray-400 w-40"
                >
                    Mannschaftsname 3
                </label>
                <input
                    type="text"
                    id="mannschaft3"
                    className="shadow-sm flex-1 bg-gray-200 border-none text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="Mannschaftsname 3 hier eintragen (falls erwünscht)"
                    value={formData.mannschaft3}
                    onChange={handleChange}
                />
            </div>

            <div className="flex">
                Die Spielernamen können am Tag gemeldet werden. 
            </div>

            <div className="pt-4 ">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Kontaktdaten</h3>
            </div>
            
            <div className="flex flex-row gap-5">
                <label
                    for="message"
                    className="self-center text-sm font-medium text-gray-900 dark:text-gray-400 w-40"
                >
                    Ansprechpartner
                </label>
                <input
                    type="text"
                    id="name"
                    className="shadow-sm flex-1 bg-gray-200 border-none text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="Name hier eingeben"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
            </div>

            <div className="flex flex-row gap-5">
                <label
                    for="message"
                    className="self-center text-sm font-medium text-gray-900 dark:text-gray-400 w-40"
                >
                    Adresse
                </label>
                <input
                    type="text"
                    id="address"
                    className="shadow-sm flex-1 bg-gray-200 border-none text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="PLZ, Ort, Straße, Hausnummer"
                    value={formData.address}
                    onChange={handleChange}
                    required
                />
            </div>

            <div className="flex flex-row gap-5">
                <label
                    for="message"
                    className="self-center text-sm font-medium text-gray-900 dark:text-gray-400 w-40"
                >
                    Telefonnummer
                </label>
                <input
                    type="text"
                    id="number"
                    className="shadow-sm flex-1 bg-gray-200 border-none text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="Nummer hier eingeben"
                    value={formData.number}
                    onChange={handleChange}
                    required
                />
            </div>

            <div className="flex flex-row gap-5">
                <label
                    for="message"
                    className="self-center text-sm font-medium text-gray-900 dark:text-gray-400 w-40"
                >
                    E-Mail
                </label>
                <input
                    type="text"
                    id="email"
                    className="shadow-sm flex-1 bg-gray-200 border-none text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="Email hier eingeben"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
            </div>

            <div className="flex">
                Die Anmeldung muss bis spätestens 19.12.2024 erfolgen.
            </div>
            
            <button
              type="submit"
              className="py-3 px-5 text-sm font-medium text-center  text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-blue
                        -800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Anmelden
            </button>
          </form>
        </div>
{/*         <div className="w-full sm:w-4/5 lg:w-3/4 mx-auto px-4 pt-10 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 lg:gab-8 justify-center text-sm lg:text-lg">
          <p className="block py-3 px-5 text-gray-70">Coming soon</p>
        </div> */}
      </div>
    </section>
  );
}
