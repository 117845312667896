

import { createClient } from '@supabase/supabase-js';
import secureLocalStorage from "react-secure-storage"

// Use a custom secure storage solution for the Supabase client to store the JWT
const SecureStoreAdapter = {
  getItem: (key: string) => {
    return secureLocalStorage.getItem(key);
  },
  setItem: (key: string, value: string) => {
    
    secureLocalStorage.setItem(key, value);
  },
  removeItem: (key: string) => {
    secureLocalStorage.removeItem(key);
  },
};

const url = 'https://zptimuzupaojeumfzkde.supabase.co';
const key = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpwdGltdXp1cGFvamV1bWZ6a2RlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzI0NDY1NjYsImV4cCI6MjA0ODAyMjU2Nn0.E2BuKrpNh3KWaKwc88BEGX4GG7T2SpTP3B0VSwnLcO8';

// Initialize the Supabase client
export const supabase = createClient(url!, key!, {
  auth: {
    storage: SecureStoreAdapter as any,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  },
});
 