import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Anfahrt from '../pages/Anfahrt'
import Chronic from '../pages/Chronic'
import Contact from '../pages/Contact'
import Datenschutz from '../pages/Datenschutz'
import FAQ from '../pages/FAQ'
import Home from '../pages/Home'
import Impressum from '../pages/Impressum'
import Mannschaften from '../pages/Mannschaften'
import News from '../pages/News'
import Vorstand from '../pages/Vorstand'
import Post from '../pages/Post'
import Docs from '../pages/Docs'
import DorfSpieltTT from '../pages/DorfSpieltTT'

export default function Navigation() {
  return (
    <BrowserRouter>
        <Routes>
            <Route exact path='/' Component={Home} />
            <Route path='/news' Component={News} />
            <Route path='/news/:id' Component={Post} />
            <Route path='/mannschaften' Component={Mannschaften} />
            <Route path='/vorstand' Component={Vorstand} />
            <Route path='/chronik' Component={Chronic} />
            <Route path='/anfahrt' Component={Anfahrt} />
            <Route path='/kontakt' Component={Contact} />
            <Route path='/impressum' Component={Impressum} />
            <Route path='/datenschutz' Component={Datenschutz} />
            <Route path='/faq' Component={FAQ} />
            <Route path='/docs' Component={Docs} />
            <Route path='/dorfspielttt' Component={DorfSpieltTT} />
        </Routes>
    </BrowserRouter>
  )
}
