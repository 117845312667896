import React from 'react'

function JugendTrainingInfo() {
    return (
        <section className="bg-white dark:bg-gray-900 mt-8 mb-8">
            <div className="mb-60px text-center px-2 max-w-screen-xl mx-auto">
                <h2 className="mb-4 text-2xl sm:text-3xl md:text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Information zum Jugendtraining</h2>
                <p className="text-gray-500 text-sm mt-3 sm:text-lg md:text-xl font-bold dark:text-gray-400">
                Liebe Tischtennisfreunde und Interessierte:
                </p>
                <p className="text-gray-500 text-sm mb-5 mt-2 sm:text-lg md:text-xl dark:text-gray-400">
                Da zur Zeit schon sehr viele Jugendliche an 
                unserem Training teilnehmen und wir nur ein begrenztes Platzangebot haben, 
                können momentan keine neuen Anmeldungen zum Jugendtraining angenommen werden. 
                Wir bitten um Verständnis.
                </p>
            </div>
        </section>
    )
}

export default JugendTrainingInfo